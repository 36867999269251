<template>
  <subcategory
    subcategoryName="Tricking/Trampoline"
    :articles="articles"
  ></subcategory>
</template>
<script charset="utf-8">
import subcategory from "@/components/subcategory.vue";

export default {
  components: {
    subcategory,
  },
  data() {
    return {
      articles: [
        {
          name: "Changing the barani twisting side",
          description:
            "If your barani twisting side is not the one you want, this might be for you",
          sneakPic: require("@/assets/article-sneak-pics/typing-faster.jpg"),
          sneakPicAlt: "barani",
          link: "/Articles/Sports/barani-twisting-side",
        },
      ],
    };
  },
};
</script>
