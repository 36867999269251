<template>
  <div class="center-wrapper">
    <div class="main-wrapper">
      <h1>{{ subcategoryName }}</h1>
      <div class="article" v-for="article in articles" :key="article">
        <router-link :to="article.link" class="no-style">
          <h2>{{ article.name }}</h2>
          <div class="content">
            <p class="description">{{ article.description }}</p>
            <img :src="article.sneakPic" :alt="article.sneakPicAlt" />
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script charset="utf-8">
export default {
  name: "subcategory",
  props: {
    subcategoryName: {
      type: String,
    },
    articles: {
      type: Object,
    },
  },
};
</script>

<style lang="scss">
.article {
  padding: 10px;
  border: solid 1px white;
  border-radius: 15px;
  h2 {
    margin-top: 7px;
  }
  .content {
    margin-top: -20px;
    display: flex;
    align-items: center;
    img {
      width: 200px;
      border-radius: 15px;
    }
    @media (max-width: 500px) {
      flex-direction: column;
      img {
        width: 100%;
      }
    }
    .description {
      text-align: justify;
      margin-right: 20px;
    }
  }
}
</style>
